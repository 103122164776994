<template>
  <div class="industries-utilities">
    <section
      id="hero-utilities"
      class="hero hero-emphasis is-bold has-text-white has-text-centered"
    >
      <nav-industries active_id="utilities"></nav-industries>

      <div class="hero-body">
        <h1 class="title has-text-white is-size-1">Smart Utilities</h1>

        <div class="columns">
          <div class="column has-text-right">
            <img class="hero-feature" src="/img/feature-utilities.jpg">
          </div>

          <div class="column has-text-left">
            <h2 class="subtitle has-text-white">
              <p>From the Remote Operations Capital of the World...</p>
            </h2>

            <p>We work with your team to lift your operational performance to new levels.</p>

            <br>
            <p>We've delivered industry award winning outcomes in Australia, Europe and the US with our clients.</p>

            <br>
            <p>We are always up for a challenge, and we're motivated by client results.</p>
          </div>
        </div>
      </div>
    </section>

    <section class="hero is-bold is-light has-text-centered">
      <div class="hero-body">
        <div class="container">
          <div class="tile is-ancestor">
            <div class="tile is-parent">
              <div class="box">
                <div class="circle-icon">
                  <i class="far fa-lightbulb"></i>
                </div>
                <h3 class="is-size-5">Advisory &amp; Strategy</h3>
                <p class="has-text-left">
                  If you're looking for a trusted, independent and experienced technical advisor to assist with feasibility studies, business
                  strategy development, product comparisons or business case development, talk to us to arrange an obligation
                  free consultation.
                </p>
              </div>
            </div>

            <div class="tile is-parent">
              <div class="box">
                <div class="circle-icon">
                  <i class="fa fa-signal"></i>
                </div>
                <h3 class="is-size-5">Smart Assets</h3>
                <p class="has-text-left">
                  Do you have lots of raw data, however you're not entirely sure how to use this to unlock value for your business? We can
                  apply our broad operational knowledge alongside our analytics capability to reveal new insights and opportunities
                  for your business. Not sure what infrastructure, applications or tools you need? We can help with that too.
                </p>
              </div>
            </div>

            <div class="tile is-parent">
              <div class="box">
                <div class="circle-icon">
                  <i class="fas fa-compress-arrows-alt"></i>
                </div>
                <h3 class="is-size-5">Integrated Operations</h3>
                <p class="has-text-left">
                  If your people are still typing in data from your control system or LIMS, talk to us to discover how we can develop automated
                  reporting solutions for your business. Unlock time, boost productivity, eliminate human error and get
                  the information you need faster. From a simple shift report, to an enterprise dashboard, or even a mobile
                  device solution, find out how we can turbo charge your reporting.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="hero is-medium is-bold is-light">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Better Productivity</h1>
          <h2
            class="subtitle"
          >Our solutions enable you to get more out of your inputs and your assets</h2>
        </div>
      </div>
    </section>

    <section class="hero is-medium is-bold is-dark has-text-right">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Better Decisions</h1>
          <h2 class="subtitle">Driving better results through technology</h2>
        </div>
      </div>
    </section>

    <section class="hero is-medium is-bold is-light">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Better Governance &amp; Compliance</h1>
          <h2
            class="subtitle"
          >A better run operations allows you to bank multiple benefits - for profit, for people and for planet</h2>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
#hero-utilities {
  background-image: url("/img/banner-utilities.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
}
</style>

<script>
import NavIndustries from '@/components/NavIndustries.vue'

export default {
  name: 'industries-utilities',
  components: {
    NavIndustries
  }
}
</script>
